import React, { useEffect, useRef, useState } from "react";
import { MdBedroomParent, MdCancel, MdVpnKey } from "react-icons/md";
import { FaTrash, FaUser } from "react-icons/fa6";
import dayjs from "dayjs";
import { get } from "../../../services/ApiRouting";
import { Dropdown, Spin, message } from "antd";
import NotFoundComman from "../../NotFound/NotFoundComman";
import { getProfile, getToken } from "../../../services/Authentication";
import { useDrawerContext } from "../../../context/DrawerControlContext";
import { FaDoorOpen, FaEdit } from "react-icons/fa";

export default function BookingList({
  dateRange,
  searchText,
  data,
  fetchData,
  handleCreateBooking,
}) {
  const [calenderList, setCalenderList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { access_token } = getToken();
  const { profile } = getProfile();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const { setDrawerData, drawerData } = useDrawerContext();
  const [rooms, setRooms] = useState([]);
  const [heights, setHeights] = useState([]);
  const tdRefs = useRef([]);

  // Function to get the height of the div
  const getDivHeight = () => {
    return tdRefs.current.map((ref) => (ref ? ref.clientHeight : 0));
  };

  useEffect(() => {
    setHeights(getDivHeight());
  }, [calenderList]);

  const handleGetDateList = () => {
    const datesInRange = [];
    const currentDate = new Date(dateRange[0]);
    const endDateObj = new Date(dateRange[1]);

    // Reset time to midnight for accurate date comparison
    currentDate.setHours(0, 0, 0, 0);
    endDateObj.setHours(0, 0, 0, 0);

    while (currentDate <= endDateObj) {
      datesInRange.push(currentDate.toISOString().split("T")[0]); // Push the date in YYYY-MM-DD format
      currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
    }

    datesInRange.splice(0, 1);

    setCalenderList(datesInRange);
  };

  useEffect(() => {
    const render = setTimeout(() => {
      handleGetDateList();
    }, 500);

    return () => clearTimeout(render);
  }, [dateRange]);

  // =========== Find Data Width ==========
  const findWidth = (from, to) => {
    const datesInRange = [];
    const currentDate = new Date(from);
    const endDateObj = new Date(to);
    while (currentDate <= endDateObj) {
      datesInRange.push(currentDate.toISOString().split("T")[0]); // Push the date in YYYY-MM-DD format
      currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
    }

    return 150 * datesInRange.length;
  };

  const fetchRoomData = async (page, limit, search) => {
    try {
      setLoading(true);
      const header = {
        Authorization: "Bearer " + access_token,
      };
      const resp = await get(
        `/rooms-detail/${profile.account_id}/${page - 1}/${limit}${
          search !== "" ? "/" + search : ""
        }`,
        header
      );
      if (resp) {
        setLoading(false);
        setRooms(resp.data);
        fetchData();
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const renderTime = setTimeout(() => {
      fetchRoomData(0, 10, searchText);
    }, 300);

    return () => clearTimeout(renderTime);
  }, [page, limit, searchText]);

  const selectedBooking = (value) => {
    setDrawerData({
      title: "Checkout Details",
      width: 450,
      type: "BookingDetail",
      isOpen: true,
      value: value,
    });
  };

  const filterData = (valueName, value) =>
    data.filter((valueCheck) => {
      const currentDate = new Date(valueCheck.from);
      const endDateObj = new Date(valueCheck.to);
      const selectedDate = new Date(dateRange[0]);
      const datesInRange = [];
      selectedDate.setHours(0, 0, 0, 0); // Reset hours to ensure comparison is only by date

      while (currentDate <= endDateObj) {
        if (currentDate >= selectedDate) {
          datesInRange.push(currentDate.toISOString().split("T")[0]); // Push the date in YYYY-MM-DD format
        }
        currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
      }
      valueCheck.from = datesInRange[0];

      return (
        valueName.room_villa_name === valueCheck.room &&
        datesInRange[0] === dayjs(value).format("YYYY-MM-DD")
      );
    });

  const handleViewBookingDetail = (title, typeCheck, id) => {
    setDrawerData({
      title: title,
      type: typeCheck,
      width: 400,
      isOpen: true,
      id: id,
    });
  };

  return (
    <Spin spinning={loading}>
      <div className="bookingDetail">
        <table>
          <tbody className="table_detail">
            <tr className="table_row">
              <td>
                <div className="table_head">
                  <table>
                    <thead>
                      <tr>
                        <td className="table_cell sticky-cell">
                          <MdBedroomParent /> Rooms
                        </td>
                        {calenderList.map((value) => (
                          <td className="table_cell">
                            {dayjs(value).format("DD MMM YYYY")}
                          </td>
                        ))}
                      </tr>
                    </thead>
                  </table>
                </div>
              </td>
            </tr>

            {rooms.map((valueName, i) => (
              <tr className="table_row" key={i}>
                <td>
                  <div className="table_head_body">
                    <table>
                      <thead>
                        <tr>
                          <td className="table_cell sticky-cell">
                            <h6>{valueName.room_villa_name}</h6>
                          </td>
                          {calenderList.map((value, index) => (
                            <td
                              className="table_cell booking-cell"
                              key={index}
                              style={{
                                height:
                                  filterData(valueName, value).length * 50,
                              }}
                            >
                              {filterData(valueName, value).length > 0 ? (
                                <div className="dataGet d-flex align-items-start flex-column m-0 py-2">
                                  {filterData(valueName, value).map(
                                    (valueData) => (
                                      <Dropdown
                                        style={{ maxWidth: "200px" }}
                                        menu={{
                                          items: [
                                            {
                                              key: 1,
                                              label: (
                                                <span
                                                  style={{ fontSize: "1rem" }}
                                                >
                                                  Edit
                                                </span>
                                              ),
                                              onClick: () =>
                                                handleViewBookingDetail(
                                                  "Edit Booking",
                                                  "EditBooking",
                                                  valueData.booking_id
                                                ),

                                              icon: (
                                                <FaEdit
                                                  style={{ fontSize: "1rem" }}
                                                  className="iconButtonAction edit"
                                                />
                                              ),
                                            },
                                            {
                                              key: 2,
                                              label:
                                                valueData.status ===
                                                "booked" ? (
                                                  <span
                                                    style={{ fontSize: "1rem" }}
                                                  >
                                                    Check In
                                                  </span>
                                                ) : (
                                                  <span
                                                    style={{ fontSize: "1rem" }}
                                                  >
                                                    Check Out
                                                  </span>
                                                ),
                                              onClick: () =>
                                                valueData.status === "booked"
                                                  ? handleViewBookingDetail(
                                                      "Check In",
                                                      "CheckIn",
                                                      valueData.booking_id
                                                    )
                                                  : handleViewBookingDetail(
                                                      "Check Out",
                                                      "CheckOut",
                                                      valueData.booking_id
                                                    ),
                                              icon: (
                                                <MdVpnKey
                                                  style={{ fontSize: "1rem" }}
                                                  className="iconButtonAction success"
                                                />
                                              ),
                                            },
                                            {
                                              key: 3,
                                              label: (
                                                <span
                                                  style={{ fontSize: "1rem" }}
                                                >
                                                  Cancel Room
                                                </span>
                                              ),
                                              onClick: () =>
                                                handleViewBookingDetail(
                                                  "Cancel Room",
                                                  "CancelRoom",
                                                  valueData.booking_id
                                                ),
                                              icon: (
                                                <MdCancel
                                                  style={{ fontSize: "1rem" }}
                                                  className="iconButtonAction remove"
                                                />
                                              ),
                                            },
                                          ],
                                        }}
                                        placement="bottomRight"
                                        arrow
                                      >
                                        <div
                                          className={`booking_data ${valueData.status.replace(
                                            / /g,
                                            "-"
                                          )}`}
                                          style={{
                                            width: findWidth(
                                              valueData.from,
                                              valueData.to
                                            ),
                                            left: (index + 1) * 150,
                                          }}
                                          // onClick={() =>
                                          //   selectedBooking(valueData)
                                          // }
                                        >
                                          <div
                                            className={`booking_person_detail d-flex align-items-center w-100`}
                                            style={{ overflow: "hidden" }}
                                          >
                                            <div
                                              className="userDetail d-flex align-items-center w-100"
                                              style={{ maxWidth: "150px" }}
                                            >
                                              <FaUser />{" "}
                                              <h6>{valueData.name}</h6>
                                            </div>
                                            <div className="roomDetail mx-4 d-flex justify-content-center align-items-center">
                                              <FaDoorOpen />
                                              {valueData.quantity}
                                            </div>
                                          </div>
                                        </div>
                                      </Dropdown>
                                    )
                                  )}
                                </div>
                              ) : (
                                <div
                                  className="w-100 h-100"
                                  onClick={() => handleCreateBooking(value)}
                                ></div>
                              )}
                            </td>
                          ))}
                        </tr>
                      </thead>
                    </table>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {rooms?.length === 0 && (
        <NotFoundComman
          title="No Room Found !"
          description=""
          img="https://www.iconpacks.net/icons/2/free-sad-face-icon-2691-thumb.png"
        />
      )}
    </Spin>
  );
}
