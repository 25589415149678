import React, {
  createContext,
  useContext,
  useRef,
  useState,
} from "react";
import { Layout, theme } from "antd";
import Sidebar from "../components/layout/sidebar";
import Header from "../components/layout/header";

const LayoutContext = createContext();

export const useLayoutContext = () => useContext(LayoutContext);

const LayoutProvider = ({ children, title }) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const { Content } = Layout;

  const [isOpen, setIsOpen] = useState(true);
  const [open, setOpen] = useState(false);
  const divRef = useRef();

  const contextValue = {
    isOpen,
    setIsOpen,
    open,
    setOpen,
  };

  return (
    <LayoutContext.Provider value={contextValue}>
      <Layout style={{padding:"10px", background:"#eee"}}>
        <Sidebar isOpen={isOpen} />
        <Layout>
          <Header
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            colorBgContainer={colorBgContainer}
            title={title}
          />
          <div className="contentDiv d-flex">
            <Content
              ref={divRef}
              style={{
                margin: "24px 16px",
                height: "86vh",
                overflowY: "auto",  
                borderRadius:"10px",
                background: colorBgContainer,
              }}
            >
              {children}
            </Content>
          </div>
        </Layout>
      </Layout>
    </LayoutContext.Provider>
  );
};

export default LayoutProvider;
