import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  List,
  Segmented,
  message,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { FaTrashAlt } from "react-icons/fa";
import TextArea from "antd/es/input/TextArea";
import { get, post } from "../../../services/ApiRouting";
import { getProfile, getToken } from "../../../services/Authentication";

export default function Aminities() {
  const [selectedTab, setSelectedTab] = useState("Room Aminities");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const checkBoxRefs = useRef();
  const [checkedId, setCheckedId] = useState([]);
  const [form] = Form.useForm();
  const {access_token} = getToken();
  const {profile} = getProfile();

  const fetchPriceDetail = async (type) => {
    setLoading(true);
    const resp = await get("/room-other-detail/" + type);
    setData(resp.data);
    setLoading(false);
  };

  useEffect(() => {
    fetchPriceDetail("categorie");
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const formData = new FormData();
      let newData = {};

      for (const key in values) {
        if (Object.hasOwnProperty.call(values, key)) {
          const value = values[key];
          formData.append(key, value);
          newData[key] = value; // Corrected: Use square brackets to set dynamic key
        }
      }
      formData.append("type", "categorie");
      formData.append("account_id_reff", profile.account_id);

      const header = {
        Authorization: "Bearer " + access_token,
      };

      const resp = await post("/room-other-detail/", formData, header);
      if (resp) {
        const prevData = data;
        prevData.push(newData);
        setData([...prevData]);
        setLoading(false);
        message.success("Upload succesfully");
        form.resetFields();
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Function to handle "Check All" checkbox change
  const handleCheckAllChange = (e) => {
    let data = [];
    if (e.target.checked) {
      const checkboxes = document.querySelectorAll('input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        data.push(checkbox.value);
      });
      setCheckedId([...data]);
    } else {
      setCheckedId([]);
    }
  };

  const handleSingleChecked = (id) => {
    const prevData = checkedId;
    const index = prevData.findIndex((value) => value === id);
    if (index !== -1) {
      prevData.splice(index, 1);
    } else {
      prevData.push(id);
    }
    setCheckedId([...prevData]);
  };
  return (
    <div>
      <Segmented
        defaultValue={selectedTab}
        style={{
          padding: 5,
          background: "#808080",
          color: "#fff",
        }}
        onChange={(value) => setSelectedTab(value)}
        options={["Room Aminities", "Add Aminities"]}
      />
      <Divider />
      {selectedTab === "Room Aminities" ? (
        <>
          <div className="d-flex align-items-center justify-content-between">
            <Checkbox onChange={handleCheckAllChange}>Select All</Checkbox>
            <Button danger type="primary" disabled={checkedId.length === 0}>
              <FaTrashAlt />
              &nbsp;Removed
            </Button>
          </div>
          <Divider />
          <List
            loading={loading}
            itemLayout="horizontal"
            dataSource={data}
            renderItem={(item, index) => (
              <List.Item key={index}>
                <List.Item.Meta
                  avatar={
                    <Checkbox
                      id="checkDataId"
                      name={"id" + index}
                      value={item.id}
                      ref={checkBoxRefs}
                      checked={checkedId.some((value) => value == item.id)}
                      onChange={() => handleSingleChecked(item.id)}
                    />
                  }
                  title={<strong>{item.name}</strong>}
                  description={
                    <p
                      style={{
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                    >
                      {item.description}
                    </p>
                  }
                />
              </List.Item>
            )}
          />
        </>
      ) : (
        <Form layout="vertical" onFinish={handleSubmit} form={form}>
          <Form.Item label="Title" name="name">
            <Input placeholder="Title" className="formInput" />
          </Form.Item>
          <Form.Item label="Description" name="description">
            <TextArea
              placeholder="Description"
              showCount
              maxLength={500}
              rows={5}
              className="formInput"
            />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" className="uploadButton w-100">
              Submit
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
}
