import { Button, Divider, Input } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import { FiRefreshCcw } from "react-icons/fi";

export default function PageTitle({
  buttonText,
  link,
  icon,
  isButtonShow,
  handleAdd,
  isShwoButton,
  moreButton,
  refreshData,
  handleListSearch
}) {
  const navigate = useNavigate();
console.log(isShwoButton)
  return (
    <>
      <div className="pageTitleDiv">
        <div className="d-flex justify-content-between align-items-center">
          <div className="searhFilter d-flex">
            <Input
              prefix={<FiSearch />}
              className="searchInput"
              placeholder="Search Here..."
              onInput={(e)=>handleListSearch(e.target.value)}
            />
            <Button className="addButtons mx-3" onClick={refreshData} icon={<FiRefreshCcw />}>
              Refresh Data
            </Button>
          </div>

          <div>
            {!isShwoButton ? (
              <Button
                className={`addButtons ${moreButton ? "expandButton" : ""}`}
                onClick={() => (!isButtonShow ? navigate(link) : handleAdd())}
              >
                <span>{icon}</span>&nbsp; <span>{buttonText}</span>
              </Button>
            ) : (
              isShwoButton
            )}

            {moreButton &&
              moreButton.map((value) => (
                <Button
                  className="addButtons expandButton"
                  onClick={value.onclick}
                >
                  <span>{value.icon}</span>&nbsp; <span>{value.name}</span>
                </Button>
              ))}
          </div>
        </div>
        <Divider />
      </div>
    </>
  );
}
