import React from "react";
import { getProfile } from "../services/Authentication";
import { Avatar, Divider, Tabs } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import {
  FaFacebook,
  FaLinkedin,
  FaWhatsappSquare,
  FaYoutube,
} from "react-icons/fa";
import {
  FaSquareInstagram,
  FaSquareThreads,
  FaSquareXTwitter,
} from "react-icons/fa6";
import ProfileDetail from "../components/Profile/ProfileDetail";
import StyleDetail from "../components/Profile/StyleDetail";
import AccountDetail from "../components/Profile/AccountDetail";
import PaymentDetail from "../components/Profile/PaymentDetail";
import SocialMedia from "../components/Profile/SocialMedia";

export default function Profile() {
  const { profile } = getProfile();

  const socialList = [
    {
      label: "Facebook",
      value: "facebook",
      icon: <FaFacebook />,
    },
    {
      label: "Instagram",
      value: "instagram",
      icon: <FaSquareInstagram />,
    },
    {
      label: "Twitter",
      value: "twitter",
      icon: <FaSquareXTwitter />,
    },
    {
      label: "You Tube",
      value: "youtube",
      icon: <FaYoutube />,
    },
    {
      label: "What's App",
      value: "whatsapp",
      icon: <FaWhatsappSquare />,
    },
    {
      label: "LinkedIn",
      value: "linkedin",
      icon: <FaLinkedin />,
    },
    {
      label: "Threads",
      value: "threads",
      icon: <FaSquareThreads />,
    },
  ];

  return (
    <div className="container py-3">
      <div
        className="banner"
        style={{
          background: "#808080",
          borderRadius: "10px",
        }}
      >
        {profile.account.profile_banner ? (
          <img
            src={profile.account.profile_banner}
            alt="banner"
            onerror=""
            style={{
              width: "100%",
              maxHeight: "250px",
              minHeight: "250px",
              borderRadius: "10px",
              objectFit: "cover",
              opacity: 0.7,
            }}
          />
        ) : null}
      </div>
      <div className="row px-4">
        <div className="col-xl-3 col-lg-4">
          <div className="profileCard">
            <Avatar size={100} src={profile.image} icon={<UserOutlined />} />
            <h4>{profile.name}</h4>
            <h6>{profile.role}</h6>
            <Divider orientation="left">Business</Divider>
            <div className="aboutBusiness">
              <h5>{profile.account.account_name}</h5>
              <div className="contactInfo my-2">
                <div>{profile.account.account_phone}</div>
                <div>{profile.account.account_email}</div>
              </div>
              <p>{profile.account.account_description}</p>
            </div>
            <div className="socialMedia">
              <ul>
                {profile?.account?.social.map((item) => (
                  <li>
                    <NavLink target="_blank" to={item.url}>
                      {
                        socialList?.find((value) => value.value == item.name)
                          ?.icon
                      }
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="col-xl-9 col-lg-8">
          <Tabs
            defaultActiveKey="1"
            items={[
              {
                key: "1",
                label: "Account",
                children: <AccountDetail />,
              },
              {
                key: "2",
                label: "Profile",
                children: <ProfileDetail />,
              },
              {
                key: "3",
                label: "Style",
                children: <StyleDetail />,
              },
              {
                key: "4",
                label: "Payment",
                children: <PaymentDetail />,
              },
              {
                key: "5",
                label: "Social Media",
                children: <SocialMedia />,
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
}
