import { Drawer } from "antd";
import React from "react";
import { useDrawerContext } from "../../context/DrawerControlContext";
import AddCategory from "../Forms/MenuCategoryForm/AddCategory";
import UpdateCategory from "../Forms/MenuCategoryForm/UpdateCategory";
import PriceRules from "../Forms/Rooms/PriceRules";
import RoomType from "../Forms/Rooms/RoomType";
import AddRoom from "../Forms/Rooms/AddRoom";
import Aminities from "../Forms/Rooms/Aminities";
import AddDiscount from "../Forms/DiscountForm/AddDiscount";
import EditDiscount from "../Forms/DiscountForm/EditDiscount";
import UpdateRoom from "../Forms/Rooms/UpdateRoom";
import CreateBooking from "../Forms/BookingForm/CreateBooking";
import AddBranch from "../Forms/Branches/AddBranch";
import CheckoutForm from "../Forms/BookingForm/CheckoutForm";
import BookingDetail from "../Forms/BookingForm/BookingDetail";
import RoomDetail from "../Forms/Rooms/RoomDetail";
import EditBooking from "../Forms/BookingForm/EditBooking";
import CheckOut from "../Forms/BookingForm/CheckOut";
import CheckIn from "../Forms/BookingForm/CheckIn";
import CancelRoom from "../Forms/BookingForm/CancelRoom";

export default function StickyDrower({}) {
  const { drawerData, closedDrawer } = useDrawerContext();

  return (
    <Drawer
      zIndex={1000000}
      width={drawerData.width}
      title={drawerData.title}
      placement="right"
      onClose={closedDrawer}
      open={drawerData.isOpen}
    >
      {drawerData.type === "categAdd" && <AddCategory />}
      {drawerData.type === "categUpdate" && <UpdateCategory />}
      {drawerData.type === "PriceRules" && <PriceRules />}
      {drawerData.type === "RoomType" && <RoomType />}
      {drawerData.type === "AddRoom" && <AddRoom />}
      {drawerData.type === "UpdateRoom" && <UpdateRoom />}
      {drawerData.type === "aminities" && <Aminities />}
      {drawerData.type === "AddDiscount" && <AddDiscount />}
      {drawerData.type === "EditDiscount" && <EditDiscount />}
      {drawerData.type === "CreateBooking" && <CreateBooking />}
      {drawerData.type === "AddBranches" && <AddBranch />}
      {drawerData.type === "ChekoutRoom" && <CheckoutForm />}
      {drawerData.type === "BookingDetail" && <BookingDetail />}
      {drawerData.type === "RoomDetail" && <RoomDetail />}
      {drawerData.type === "CheckIn" && <CheckIn />}
      {drawerData.type === "CheckOut" && <CheckOut />}
      {drawerData.type === "EditBooking" && <EditBooking />}
      {drawerData.type === "CancelRoom" && <CancelRoom />}
    </Drawer>
  );
}
