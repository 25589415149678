import { Avatar, Button, Layout } from "antd";
import React from "react";
import { MdArrowForwardIos } from "react-icons/md";
import { MdArrowBackIosNew } from "react-icons/md";
import { UserOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { getProfile, getToken } from "../../services/Authentication";
import { useDispatch } from "react-redux";
import { setUserToken } from "../../features/authSlice";

const { Header } = Layout;
export default function HeaderComp({
  isOpen,
  setIsOpen,
  colorBgContainer,
  title,
}) {
  const { access_token } = getToken();
  const { profile } = getProfile();

  const dispatch = useDispatch();

  useEffect(() => {
    if (access_token) {
      dispatch(setUserToken({ token: access_token }));
    }
  }, [access_token]);

  return (
    <Header
      style={{
        padding: "0 10px",
        background: colorBgContainer,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        margin: "0 14px",
        borderRadius: "10px",
      }}
    >
      <div className="pageDetail d-flex align-items-center">
        <Button
          type="text"
          icon={isOpen ? <MdArrowForwardIos /> : <MdArrowBackIosNew />}
          onClick={() => setIsOpen(!isOpen)}
          style={{
            fontSize: "20px",
            width: 40,
            height: 40,
            border: "2px solid #000",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        />
        <h3 className="mx-3">{title}</h3>
      </div>
      {profile?.account?.logo ? (
        <div className="logo">
          <img
            style={{ width: "100%", maxWidth: "60px", borderRadius: "50px" }}
            src={profile?.account?.logo}
          />
        </div>
      ) : null}
      <div className="loginDetails d-flex justify-content-center align-items-center">
        <Avatar
          size={45}
          src={profile?.image}
          icon={<UserOutlined />}
          className="mx-2"
        />
        <div style={{ lineHeight: 2 }}>
          <h5 className="m-0 p-0">{profile?.name}</h5>
          <span className="m-0 muted" style={{ lineHeight: 0 }}>
            {profile?.role}
          </span>
        </div>
      </div>
    </Header>
  );
}
