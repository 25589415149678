const storeToken = (value, isRemeber, data) => {
  if (value) {
    if (isRemeber) {
      localStorage.setItem("access_token", value);
      localStorage.setItem("profile", JSON.stringify(data));
    } else {
      sessionStorage.setItem("access_token", value);
      sessionStorage.setItem("profile", JSON.stringify(data));
    }
  }
};

const getToken = () => {
  let access_token = localStorage.getItem("access_token");
  if (access_token) {
    access_token = localStorage.getItem("access_token");
  } else {
    access_token = sessionStorage.getItem("access_token");
  }
  return { access_token };
};

const getProfile = () => {
  let profile = JSON.parse(localStorage.getItem("profile"));
  if (profile) {
    profile = JSON.parse(localStorage.getItem("profile"));
  } else {
    profile = JSON.parse(sessionStorage.getItem("profile"));
  }
  return { profile };
};

const removeToken = () => {
  sessionStorage.clear();
  localStorage.clear();
  
};

export { storeToken, getToken, removeToken, getProfile };
