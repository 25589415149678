import React, { useState } from "react";
import password from "../assets/6321602.jpg";
import EmailInput from "../components/PasswordReset/EmailInput";
import PasswordInput from "../components/PasswordReset/PasswordInput";
import { FaAngleLeft } from "react-icons/fa6";
import { NavLink } from "react-router-dom";

export default function ForgotPassword() {
  const [otpSubmit, setOtpSubmit] = useState(false);
  const [remberToken, setRememberToken] = useState(null);

  console.log(remberToken);
  
  return (
    <div className="container forgotePassword">
      <div className="row w-100">
        <div className="col-md-6 d-flex align-items-center justify-content-center flex-column">
          {otpSubmit ? (
            <PasswordInput remberToken={remberToken} />
          ) : (
            <EmailInput setOtpSubmit={setOtpSubmit} setRememberToken={setRememberToken} />
          )}
        </div>
        <div className="col-md-6 d-flex align-items-center justify-content-center">
          <div className="imageDiv">
            <img src={password} />
          </div>
        </div>
      </div>
    </div>
  );
}
