import React, { useState } from "react";
import { Modal, Button, Input, Form, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { post } from "../../services/ApiRouting";
import { getProfile, getToken } from "../../services/Authentication";

export default function AddUserModal() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { access_token } = getToken();
  const { profile } = getProfile();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSubmitForm = async (values) => {
    try {
      setLoading(true);
      const formData = new FormData();
      let newData = {};

      for (const key in values) {
        if (Object.hasOwnProperty.call(values, key)) {
          const value = values[key];
          formData.append(key, value);
          newData[key] = value; // Corrected: Use square brackets to set dynamic key
        }
      }
      formData.append("type", "user");
      formData.append("account_id_reff", profile.account_id);

      const header = {
        Authorization: "Bearer " + access_token,
      };

      const resp = await post("/register", formData, header);
      if (resp) {
        setLoading(false);
        message.success("Added succesffuly");
        form.resetFields();
        setIsModalOpen(false);
      }
    } catch (error) {
      setLoading(false);
      message.error("Sorry Unable to add please try again");
    }
  };

  return (
    <>
      <Button
        onClick={showModal}
        type="text"
        icon={<PlusOutlined />}
        style={{ width: "100%", backgroundColor:"#e1e1e1" }}
      >
        Create New Account
      </Button>
      <Modal
        title="Create Account"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
        width={768}
        centered
      >
        <Form layout="vertical" form={form} onFinish={handleSubmitForm}>
          <div className="row">
            <div className="col-md-6 m-0">
              <Form.Item
                label="Enter Name"
                name="name"
                rules={[{ required: true }]}
              >
                <Input className="formInput" placeholder="Full Name" />
              </Form.Item>
            </div>
            <div className="col-md-6 m-0">
              <Form.Item
                label="Enter Email"
                name="email"
                rules={[
                  {
                    required: true,
                  },
                  {
                    type: "email",
                  },
                ]}
              >
                <Input className="formInput" placeholder="Email" />
              </Form.Item>
            </div>
            <div className="col-md-6 m-0">
              <Form.Item
                label="Enter Phone"
                name="phone"
                rules={[
                  {
                    required: true,
                  },
                  {
                    min: 10,
                  },
                  {
                    max: 10,
                  },
                ]}
              >
                <Input className="formInput" placeholder="Phone" />
              </Form.Item>
            </div>
            <div className="col-md-6 m-0">
              <Form.Item label=" ">
                <Button
                  htmlType="submit"
                  loading={loading}
                  className="uploadButton"
                >
                  Submit
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
}
