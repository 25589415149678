import React, { useEffect, useState } from "react";
import { IoGridSharp } from "react-icons/io5";
import { Layout, Menu } from "antd";
import { IoMdLogOut } from "react-icons/io";
import { FaUserCircle } from "react-icons/fa";
import { MdBedroomParent, MdFoodBank } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { BiFoodTag, BiSolidFoodMenu } from "react-icons/bi";
import { IoCalendar } from "react-icons/io5";
import { removeToken } from "../../services/Authentication";
import { useDispatch } from "react-redux";
import { setUserToken } from "../../features/authSlice";
import { FaUsers } from "react-icons/fa";
import { IoIosListBox } from "react-icons/io";

const { Sider } = Layout;

export default function Sidebar({ isOpen }) {
  const menuLink = [
    {
      key: "1",
      label: "Dashboard",
      icon: <IoGridSharp />,
      path: "/dashboard",
      onClick: () => navigate("/dashboard"),
    },
    {
      key: "2",
      label: "Booking Desk",
      icon: <IoCalendar />,
      path: "/bookings",
      onClick: () => navigate("/bookings"),
    },
    {
      key: "3",
      label: "Rooms",
      icon: <MdBedroomParent />,
      path: "/rooms",
      onClick: () => navigate("/rooms"),
    },
    {
      key: "4",
      label: "Menu",
      icon: <BiSolidFoodMenu />,
      // onClick: () => navigate("/menu-list"),
      children: [
        {
          key: "5",
          label: "Categories",
          icon: <BiFoodTag />,
          path: "/categories",
          onClick: () => navigate("/categories"),
        },
        {
          key: "6",
          label: "Foods",
          icon: <MdFoodBank />,
          path: "/foods",
          onClick: () => navigate("/foods"),
        },
      ],
    },
    {
      key: "7",
      label: "Users",
      icon: <FaUsers />,
      path: "/users",
      onClick: () => navigate("/users"),
    },
    {
      key: "8",
      label: "Enquiries",
      icon: <IoIosListBox />,
      path: "/enquiry",
      onClick: () => navigate("/enquiry"),
    },
    {
      key: "9",
      label: "Profile",
      icon: <FaUserCircle />,
      path: "/profile",
      onClick: () => navigate("/profile"),
    },
    {
      key: "10",
      label: "Logout",
      icon: <IoMdLogOut />,
      onClick: () => logOut(),
    },
  ];

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedKey, setSelectedKey] = useState(["1"]);

  const path = useLocation(); // --- geting path
  const logOut = () => {
    removeToken();
    navigate("/login");
    dispatch(setUserToken({ token: null }));
  }; // --- Logout function

  function findKeysByPath(path) {
    let parentKey, childKey;

    // Iterate through the menuLink array
    menuLink.forEach((item) => {
      // Check if the item's path matches the provided path
      if (item.path === path) {
        parentKey = item.key; // Save the parent key
      } else {
        if (item.children) {
          item.children.forEach((childItem) => {
            if (childItem.path === path) {
              childKey = childItem.key; // Save the child key
            }
          });
        }
      }
    });

    return { parentKey, childKey }; // Return the keys
  }

  useEffect(() => {
    const { parentKey, childKey } = findKeysByPath(path.pathname); // Call the function to find keys
    setSelectedKey([parentKey, childKey]);
  }, [path]); // ------ Selecting deafult menu item

  return (
    <Sider
      collapsedWidth={60}
      collapsed={isOpen}
      style={{
        padding: "0 5px",
        overflow: "auto",
        height: "97vh",
        left: 0,
        top: 0,
        bottom: 0,
        borderRadius: "10px",
        background: "#001628"
      }}
    >
      {!isOpen ? (
        <div className="demo-logo-vertical d-flex justify-content-center pt-3">
          <h4 style={{ color: "#fff" }}>Admin Login</h4>
        </div>
      ) : (
        <div className="demo-logo-vertical d-flex justify-content-center pt-3">
          <h4 style={{ color: "#fff" }}>AL</h4>
        </div>
      )}
      <div
        className="d-flex flex-column align-items-center justify-content-center"
      >
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={selectedKey}
          onSelect={(value) => setSelectedKey(value.key)}
          items={menuLink?.filter(
            (value) => value.label !== "Logout" && value.label !== "Profile"
          )}
        />
        <Menu
          style={{ position: "absolute", bottom: 0 }}
          mode="inline"
          theme="dark"
          selectedKeys={selectedKey}
          onSelect={(value) => setSelectedKey(value.key)}
          items={menuLink?.filter(
            (value) => value.label === "Logout" || value.label === "Profile"
          )}
        />
      </div>
    </Sider>
  );
}
